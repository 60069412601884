.loading-component{
    min-height: 120px;
    width: 100%;
    position: relative;
}

.loading-component .loader-content{
    background: url('./../../assets/images/loading.svg') no-repeat center center;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
}