.home-wrapper {
    height: 100vh !important;
    overflow-x: hidden;
}

.scrollbar{
    overflow: hidden;
    padding: 1.5rem 1rem 1.5rem 2.844rem !important;
}
.sidenav {
    height: 100%;
    width: 18rem;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
}


.main {
    margin-left: 18rem; /* Same as the width of the sidenav */
    width: 100%;
}

.without-mid-sidenav {
    height: 100%;
    width: 40px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition:width 0.4s;
}


.without-mid-sidenav-main{
    margin-left: 40px; /* Same as the width of the sidenav */
    width: 100%;
    transition:width 1s;
}
/* .home-content {
    padding: 1.5rem 1.0rem 1.5rem 11rem !important;
} */

.home-content {
   min-height: 100vh;
   background-color: rgb(255, 255, 255) !important;
   border-top-left-radius: 25px !important;
   border-bottom-left-radius: 25px !important;
   /* box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.16); */
   box-shadow: -1px 1px 5px 0 rgba(0, 0, 0, 0.16);
}

.home-content-bg-grey {
    background-color:#f4f4f4 !important;
}


