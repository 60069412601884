html,
body,
#root {
    height: 100%;
    font-family: "Poppins", sans-serif !important;
    font-size: calc(100vw * 0.00833) !important;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f4f4f4 !important;
}

.dropdown-toggle::after {
    vertical-align: 0.155em !important;
}

.action-btn-container .action-btn {
    display: inline-block;
    cursor: pointer;
    width: 1.9rem;
    height: 1.9rem;
    text-align: center;
    overflow: hidden;
    transition: all 0.3s ease-out;
    white-space: nowrap;
}

.action-btn-container .action-btn:hover {
    width: 10rem;
}

.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
}

.ReactVirtualized__Table__row {
    padding: 0.75rem !important;
}

.cursor-pointer-hover:hover {
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-copy {
    cursor: copy;
}

.cursor-default {
    cursor: default;
}

:focus {
    outline: transparent !important;
}

.intl-tel-input .country-list {
    z-index: 3 !important;
}

.drawer {
    box-shadow: 0 0.5rem 2rem -17px rgba(0, 0, 0, 0.11) !important;
}

.badge-green {
    color: var(--green) !important;
}

.badge-red {
    color: var(--red) !important;
}

.grey-bg {
    background-color: #f4f4f4;
}

.badge-orange {
    color: #fff !important;
    background-color: #ff8200 !important;
}

.badge-teal {
    color: #000 !important;
    background-color: #20c997 !important;
}

.btn-orange {
    color: #fff;
    background-color: #fd7e14;
    border-color: #fd7e14;
}

.btn-orange:hover {
    color: #fff;
    background-color: #e96b02;
    border-color: #dc6502;
}

.btn-orange.focus,
.btn-orange:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 113, 23, 0.5);
}

.btn-orange.disabled,
.btn-orange:disabled {
    color: #fff;
    background-color: #fd7e14;
    border-color: #fd7e14;
}

.btn-outline-orange {
    color: #fd7e14;
    border-color: #fd7e14;
}

.btn-outline-orange:hover {
    color: #fff;
    background-color: #fd7e14;
    border-color: #fd7e14;
}

.btn-outline-orange.focus,
.btn-outline-orange:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 113, 23, 0.5);
}

.btn-outline-orange.disabled,
.btn-outline-orange:disabled {
    color: #fd7e14;
    background-color: initial;
}

.btn-purple {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
}

.btn-purple:hover {
    color: #fff;
    background-color: #5e37a6;
    border-color: #59339d;
}

.btn-purple.focus,
.btn-purple:focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 94, 202, 0.5);
}

.btn-purple.disabled,
.btn-purple:disabled {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
}

.badge-purple {
    color: #fff !important;
    background-color: #6f42c1 !important;
}

.badge-olive {
    color: #000 !important;
    background-color: #b5cc18 !important;
}

.badge-ADJUSTMENT {
    color: #fff;
    background-color: #6f42c1;
}

.badge-COLLECTION {
    color: #fff;
    background-color: var(--success);
}

.badge-REDEMPTION {
    color: #fff;
    background-color: var(--orange);
}

.notes-view {
    max-height: 5rem !important;
    overflow: auto;
}

.border-solid-bottom {
    border-bottom: 1px solid #efefef;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 6px;
    background: #fbfbfb;
}

::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #c0c0c0;
}

/* To fix the mobile number input validation status, prioritize is-valid is-invalid classes instead :valid :invalid pseudo code */

.form-control.is-invalid {
    border-color: #dd3444 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dd3444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dd3444' stroke='none'/%3e%3c/svg%3e") !important;
}

.form-control.is-invalid:focus {
    border-color: #dd3444 !important;
    box-shadow: 0 0 0 0.2rem rgb(221 52 68 / 25%) !important;
}

/* 
  * To disable the dropdown caret 
*/
.caret-off::before {
    display: none !important;
}
.caret-off::after {
    display: none !important;
}

.text-primary-disbaled {
    color: #5586a2;
}

.date-valid .dropdown-toggle.placeholder {
    border-color: #25bf49 !important;
}

.date-valid .form-date.dropdown .dropdown-toggle {
    border-color: #25bf49 !important;
}

.date-invalid .dropdown-toggle.placeholder {
    border-color: #dd3444 !important;
}

.date-invalid .form-date.dropdown .dropdown-toggle {
    border-color: #dd3444 !important;
}

.select-valid .custom-select {
    border-color: #25bf49 !important;
    background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="7" viewBox="0 0 12 7" version="1.1"%3e%3cg id="surface1"%3e%3cpath style=" stroke:none;fill-rule:nonzero;fill:rgb%280%,0%,0%%29;fill-opacity:1;" d="M 1.140625 0.207031 C 0.875 -0.0703125 0.449219 -0.0664062 0.191406 0.21875 C -0.0703125 0.503906 -0.0625 0.960938 0.203125 1.238281 L 5.554688 6.792969 L 6.023438 6.277344 L 5.554688 6.792969 C 5.820312 7.070312 6.246094 7.066406 6.507812 6.777344 C 6.515625 6.769531 6.519531 6.761719 6.527344 6.753906 L 11.796875 1.238281 C 12.0625 0.960938 12.070312 0.503906 11.808594 0.21875 C 11.550781 -0.0664062 11.125 -0.0742188 10.859375 0.207031 L 6.019531 5.273438 Z M 1.140625 0.207031 "/%3e%3c/g%3e%3c/svg%3e')
            right 1rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2325bf49' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
            center right 3.6rem / calc(0.5em + 0.563rem) calc(0.5em + 0.563rem)
            no-repeat !important;
}

.select-valid .custom-select:focus {
    border-color: #25bf49 !important;
    box-shadow: 0 0 0 0.2rem rgba(37, 191, 73, 0.25) !important;
    background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="7" viewBox="0 0 12 7" version="1.1"%3e%3cg id="surface1"%3e%3cpath style=" stroke:none;fill-rule:nonzero;fill:rgb%280%,0%,0%%29;fill-opacity:1;" d="M 1.140625 0.207031 C 0.875 -0.0703125 0.449219 -0.0664062 0.191406 0.21875 C -0.0703125 0.503906 -0.0625 0.960938 0.203125 1.238281 L 5.554688 6.792969 L 6.023438 6.277344 L 5.554688 6.792969 C 5.820312 7.070312 6.246094 7.066406 6.507812 6.777344 C 6.515625 6.769531 6.519531 6.761719 6.527344 6.753906 L 11.796875 1.238281 C 12.0625 0.960938 12.070312 0.503906 11.808594 0.21875 C 11.550781 -0.0664062 11.125 -0.0742188 10.859375 0.207031 L 6.019531 5.273438 Z M 1.140625 0.207031 "/%3e%3c/g%3e%3c/svg%3e')
            right 1rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2325bf49' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
            center right 3.6rem / calc(0.5em + 0.563rem) calc(0.5em + 0.563rem)
            no-repeat !important;
}

.select-invalid .custom-select {
    border-color: #dd3444 !important;
    background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="7" viewBox="0 0 12 7" version="1.1"%3e%3cg id="surface1"%3e%3cpath style=" stroke:none;fill-rule:nonzero;fill:rgb%280%,0%,0%%29;fill-opacity:1;" d="M 1.140625 0.207031 C 0.875 -0.0703125 0.449219 -0.0664062 0.191406 0.21875 C -0.0703125 0.503906 -0.0625 0.960938 0.203125 1.238281 L 5.554688 6.792969 L 6.023438 6.277344 L 5.554688 6.792969 C 5.820312 7.070312 6.246094 7.066406 6.507812 6.777344 C 6.515625 6.769531 6.519531 6.761719 6.527344 6.753906 L 11.796875 1.238281 C 12.0625 0.960938 12.070312 0.503906 11.808594 0.21875 C 11.550781 -0.0664062 11.125 -0.0742188 10.859375 0.207031 L 6.019531 5.273438 Z M 1.140625 0.207031 "/%3e%3c/g%3e%3c/svg%3e')
            right 1rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dd3444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dd3444' stroke='none'/%3e%3c/svg%3e")
            center right 3.6rem / calc(0.5em + 0.563rem) calc(0.5em + 0.563rem)
            no-repeat !important;
}

.select-invalid .custom-select:focus {
    border-color: #dd3444 !important;
    box-shadow: 0 0 0 0.2rem rgba(221, 54, 71, 0.25) !important;
    background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="7" viewBox="0 0 12 7" version="1.1"%3e%3cg id="surface1"%3e%3cpath style=" stroke:none;fill-rule:nonzero;fill:rgb%280%,0%,0%%29;fill-opacity:1;" d="M 1.140625 0.207031 C 0.875 -0.0703125 0.449219 -0.0664062 0.191406 0.21875 C -0.0703125 0.503906 -0.0625 0.960938 0.203125 1.238281 L 5.554688 6.792969 L 6.023438 6.277344 L 5.554688 6.792969 C 5.820312 7.070312 6.246094 7.066406 6.507812 6.777344 C 6.515625 6.769531 6.519531 6.761719 6.527344 6.753906 L 11.796875 1.238281 C 12.0625 0.960938 12.070312 0.503906 11.808594 0.21875 C 11.550781 -0.0664062 11.125 -0.0742188 10.859375 0.207031 L 6.019531 5.273438 Z M 1.140625 0.207031 "/%3e%3c/g%3e%3c/svg%3e')
            right 1rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dd3444' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dd3444' stroke='none'/%3e%3c/svg%3e")
            center right 3.6rem / calc(0.5em + 0.563rem) calc(0.5em + 0.563rem)
            no-repeat !important;
}

.search-bar-width {
    width: 37rem !important;
}

.search-bar-width-sm {
    width: 24rem !important;
}

.default-info-box-bg {
    background-color: #efefef !important;
}

.light-bg {
    background-color: #efefef4c;
}

.danger-bg {
    background-color: rgba(221, 54, 71, 0.03);
}

.success-bg {
    background-color: rgba(37, 193, 73, 0.03);
}

.info-div-type-secondary {
    color: var(--secondary);
    border: 1px solid rgba(0, 172, 240, 0.5);
    background-color: rgba(27, 103, 133, 0.05);
}

.border-lg-left {
    border-left: 3px solid;
}

.border-bottom-none {
    border-bottom: none !important;
}
