.sidebar {
    width: 4rem;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
    background: #eaeaea;
    transition: 0.5s;

    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        .logo {
            width: 3rem;
        }
    }

    .brand {
        text-align: center;
        font-size: 1.4rem;
        margin-top: 3rem;
        color: #000;
        .btn {
            padding: 0.2rem 0.3rem 0 1rem;
        }
        .flags-img {
            width: 1.4rem;
        }
        .dropdown-menu {
            border: 1px solid rgba(255, 255, 255, 0.15);
        }
    }

    .nav-list {
        margin-top: 2rem;
        list-style: none;
        color: #000000;
    }

    .region-dropdown-menu-active {
        margin-bottom: 10rem;
        transition: margin-bottom 0.4s;
    }

    .nav-item {
        padding: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .active-nav-item {
        padding: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-left: 0.15rem solid #107eb0;
        width: 100%;
        color: #107eb0;
    }

    .active-globe {
        color: #107eb0;
    }

    .nav-item:hover {
        background: #ffffff;
        border-left: 1px solid #107eb0;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea;
        width: 100%;
        color: #107eb0;
    }
}
